
.pageSection {
    width: 100%;
    min-height: calc(100vh - 4em);
    height: max-content !important;
    display: grid;
    grid-auto-flow: row;
    padding-bottom: 10%;
    background-color: rgba(0, 0, 0, 0.351);
    background: linear-gradient(180deg, #f0f0f0 50%, white 0);
}
#applicationsPage {
    padding-bottom: 20vmin ;
}
#aboutSection {
    height: max-content;
}
#aboutSection .aboutParts {
    width: 90%;
    padding: 1%;
    margin: auto;
    margin-bottom: 2%;
    display: grid;
    column-gap: 5%;
    background-color: #f0f0f0;
    transition: box-shadow 0.2s linear;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.aboutParts .img {
    width: 100%;
    height: 50vh;
    overflow: hidden;
}
.aboutParts .img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

footer {
    width: 100%;
    padding: 2%;
    display: grid;
    min-height: 40vh;
    position: sticky;
    text-align: center;
    grid-auto-flow: row;
    height: max-content;
    justify-items: center;
    top: calc(100% - 50vh);
    justify-content: center;
    background: #11a21641;
}
.contacts {
    width: inherit;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.contacts div {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 10%;
    height: 2em;
    line-height: 2em !important;
    line-height: 3em;
    text-align: left;
}
.contacts .callActionBtn {
    margin-right: auto !important;
}
footer .logo {
    width: 10vmax;
    margin: auto;
}
#gallerySection {
    display: grid !important;
    grid-auto-flow: row;
    gap: 2%;
    /* grid-template-rows: 10% 80% 15%; */
    flex-wrap: wrap;
}
.h1{
    margin-left: 5%;
}
#coursesSection {
    height: max-content !important;
}
/* #coursesSection .courses {
    width: 90%;
    height: max-content;
    margin: 5%;
    display: grid;
} */
#coursesSection .courses, .gallery {
    width: 90%;
    height: max-content;
    margin: 5%;
    gap: 1%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}  

#introSection {
    grid-template-rows: 50vh;
}
#introSection div {
    width: 90%;
    margin: auto;
    display: grid;
    grid-auto-flow: row;
}
#introSection .about {
    height: 90%;
    margin: 0 5%;
}
#introSection .mottos{
    display: grid;
    gap: 5%;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
}
#introSection div article{
    height: 100%;
    line-clamp: 6;
    -webkit-line-clamp: 6;
    display: -webkit-box !important;
    text-overflow: ellipsis;
    overflow: hidden;
}
#introSection div article::before {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, transparent, #f0f0f0) ;
}