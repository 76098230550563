

.notification {
    width: 300px;
    min-height: max-content;
    padding: 0.5rem;
    position: absolute;
    top: 25%;
    left: calc((100vw - (300px + 1rem)) / 2);
    background-color: white;
    z-index: 100;
    display: grid;
    grid-auto-flow: row;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.571);
}
.notification h3 {
    width: calc(100% + 1rem);
    left: -0.5rem;
    top: -0.5rem;
    height: 1.5em;
    color: #F2F2F2;
    text-indent: 0.5rem;
    background-color: #0c580e;
}

.vacBanner {
    max-width: 30rem;
    min-width: 300px;
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    padding: 1%;
    background-color: white;
    grid-template-columns: 25%;
    outline: 0.5px solid rgba(0, 0, 0, 0.137);
    /* box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.144); */
}
.vacBanner:hover {
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.249);
}
.vacBanner #vac-cont-1 {
    display: grid;
    grid-auto-flow: row;
    text-align: center;
    animation:  spin 1s linear forwards;
    cursor:default;
    background-color: white;
}
@keyframes spin {
    to{transform: rotateX(360deg);}
}
#vac-cont-1 p:nth-child(1){
    color: red;
}
.vacBanner #vac-cont-2 {
    display: grid;
}
.vacBanner span {
    width: 100%;
    min-height: 2em;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    background-color: rgb(242, 242, 242);
}
.vacBanner span p {
    margin-top: auto;
    margin-bottom: auto;
}
.vacBanner span p:nth-child(1){
    flex: 0 1 40%;
    width: 100%;
    height: max-content;
    color: #09560C;
}

.carousel {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 70vh;
    margin-top: auto !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 50px;
    height: 50px;
    margin: auto;
    border-radius: 50%;
    background-color: black;
    color: black !important;
}
.carousel img {
    height: 70vh;
    object-fit: cover !important;
}
.carousel .thumbs {
    display: none;
}

.dropDown {
    width: max-content;
    height: 4em;
    display: grid;
    margin-left: auto;
}
.dropDownBtn {
    width: max-content;
    height: 2em;
    display: grid;
    margin: auto;
    margin-right: 3vw !important;
    grid-auto-flow: row;
    background-color: white;
}
.dropDownBtn div {
    width: 30px;
    height: 2px;
    background-color: black;
    transition: transform 0.5s;
}
.dropDownList {
    position: absolute;
    min-width: 75vw;
    width: 1fr;
    flex: 1;
    height: 50vh;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    top: 100%;
    background-color: #cbffcd;
    left: -65vw;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.322);
}
.dropDownList button {
    height: 6vmax;
    margin-top: 1%;
    background-color: #ffffff;
}
.dropDownList .contacts {
    top: 100%;
    bottom: 0;
    display: grid;
    grid-auto-flow: column;
    position: sticky;
    padding-bottom: 5%;
}

form {
    width: 50vmax;
    display: grid;
    margin: auto;
    margin-top: 5%;
    grid-auto-flow: row;
    grid-auto-rows: 2.5em;
    padding: 2%;
    background-color: #F0F0F0;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.041);
}

form span {
    width: 95%;
    margin: auto;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 30%;
}

/*  */
.input1 {
    width: 100%;
    height: 2.5em;
    line-height: 2.5em;
    border: none;
    background-color: #F0F0F0;
    outline: transparent;
    padding-left: 2%;
    border-bottom: 2px solid #b9b9b9;
}
.input1:focus {
    border-bottom: 2px solid #08550b77;
}
form label {
    width: 100%;
    height: 2.5em;
    line-height: 2.5em;
}

/* call to action buttons */
.callActionBtn{
    color: #021d03;
    border-radius: 50%;
}
.callActionBtn * {
    font-size: 32px;
    width: 90%;
}


/* intakebanner styling */
.intakeBanner {
    /* margin: ; */
    display: grid;
    /* padding: auto; */
    width: 2fr;
    flex: 1;
    margin: 1rem;
    height: 20rem;
    min-width: 300px;
    text-indent: 10%;
    grid-auto-flow: row;
    background-color: white;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.151);
}
.intakeBanner h1 {
    background-color: #f0f0f0;
    height: 4rem;
    line-height: 4rem;
    font-size: 2rem;
}
.intakeBanner span {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    grid-template-columns: 30% 70%;
}
.intakeBanner span * {
    margin: auto;
    width: 100%;
}

/* show image styling */
.showImg {
    min-width: 10em;
    width: 30%;
    height: 30vh;
}
.showImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s;
}
.showImg h1 {
    font-size: 2vmax !important;
    position: absolute;
    top: 10%;
    left: 10%;
    color: white;
    filter: drop-shadow(0px 0px 12px black);
    transition: object-fit 0.5s;
}
.showImg img:hover {
    transform: scale(1.05);
    object-fit: contain;
}
/* listcourses widget  */
.listCourses {
    min-width: 40vmax;
    max-width: 500px;
    padding-bottom: 2vmax;
    gap: 2%;
    margin-bottom: 4%;
    background-color: white;
    border: 0.5px solid rgba(0, 0, 0, 0.114);
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.151);
}
.listCourses ol {
    width: 90%;
    margin-right: auto;
} 
.listCourses li {
    width: 100%;
    margin-left: auto;
}
.listCourses .courses li {
    border-bottom: 0.5px solid gray;
}
.listCourses li span {
    width: 100%;
    min-height: 3em;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 70%;
}
.listCourses li span em {
    text-align: right;
    padding-right: 5%;
}
.listCourses li span * {
    margin-top: auto;
    margin-bottom: auto;
}
.listCourses h1 {
    width: 100%;
    height: 2.5em;
    font-size: 2vmax;
    line-height: 2.5em;
    background-color: #f0f0f0;
    text-indent: 10%;
}
.listCourses h3 {
    text-indent: 10%
}

/* main navigation bar */
/* styled in app.css */

/* nav button links styling */
.navButtons {
    width: max-content;
    height: inherit;
    line-height: inherit;
    display: grid;
    grid-auto-flow: column;
}
/* navLinkBtn stylng */
.navLinkBtn {
    width: max-content;
    min-width: 15vmin;
    height: inherit;
    line-height: 4em;
    margin: auto;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 100%;
    background-color: transparent;
    border: 4px solid transparent;
}
.navLinkBtn p {
    border-bottom: 5px solid transparent;
    transition: border-bottom 0.5s;
    transform-origin: center;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.5s linear;
}
/* school logo */
.schoolLogo {
    width: max-content;
    height: 2.5em;
    line-height: 2.5em;
    display: grid;
    grid-auto-flow: column;
    margin: auto;
    margin-right: auto;
    margin-left: 2vmin;
    color: #09560C;
    cursor: pointer;
    font-weight: bolder;
    font-size: calc(10px + 2vmin);
}
.schoolLogo img {
    width: 4vmin;
    margin: auto;
}


/* landing page styling */
.landingPage {
    width: 100%;
    height: 70vh;
    background: url('../images/main.webp') rgba(0, 0, 0, 0.603);
    background-blend-mode: multiply;
    background-size: cover;
    backdrop-filter: blur(15px);
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    overflow: hidden;
}
.landingPage h1 {
    width: 100%;
    font-size: 4vmax;
    color: white !important;
}
.landingPage p {
    font-size: 3vmax;
}
.landingPage img {
    width: 20vmax;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    background: radial-gradient(rgba(0, 0, 0, 0.452), transparent);
    border-radius: 30%;
}

.button1 {
    min-width: 15vmax;
    height: 2.5em;
    color: white;
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(45deg, #08550B, #00AF92);
    transition: all 0.2s linear;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.559);
}
.button1:hover {
    opacity: 0.8;
}
.button1:active {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.148);
    background: linear-gradient(45deg, #00AF92, #08550B);
}
.button2 {
    width: 95%;
    height: 2.5em;
    margin: auto;
    color: white;
    margin-left: auto;
    margin-right: auto;
    background: #3f3f3f;
}
.mottoVis {
    height: max-content !important;
    min-height: 20vh;
    /* width: 1fr !important; */
    margin: auto !important;
    display: grid;
    grid-auto-flow: row;
    padding: 2%;
    background-color: white;
    outline: 4px solid #E5E5E5;
    transition: outline 0.02s, box-shadow 0.02s, transform 0.2s;
}
.mottoVis:hover {
    outline: 1px solid transparent;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.199);
    transform: scale(1.01);
}
