* {
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: all 0.5s ease-in-out;
    cursor: default;
}

html, body {
    background-color: rgb(255, 255, 255);
    scroll-behavior: smooth;
}
body::-webkit-scrollbar {
    width: 10px;    
    background: #838383;
}

body::-webkit-scrollbar-button {
    background: #838383;
}
body::-webkit-scrollbar-thumb {
    background: white;
}
h1 {
    color: #09560C;
}
li {
    display: list-item !important;
}
nav {
    width: 100%;
    height: 4em;
    top: 0;
    z-index: 500;
    position: sticky;
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.212);
}
.h1 {
    font-size: 3em;
    font-weight: lighter;
}

@media only screen and (max-width: 850px) {
    .carousel {
        height: 50vh !important;
    }
    .carousel img {
        height: 50vh !important;
    }
    #introSection {
        padding-bottom: 25%;
    }

    #aboutSection {
        height: max-content !important;
        display: flex !important;
        flex-direction: column;
        grid-template-columns: none !important;
    }
    form {
        width: 96% !important;
    }
    form span {
        grid-template-columns: 45% !important;
    }
    #homepage {
        height: fit-content;
        display: grid;
        grid-auto-flow: row;
    }
    #coursesSection .courses, .gallery {
        flex-direction: column;
    }
    .listCourses h1 {
        font-size: 3vmax !important;
    }
    .intakeBanner {
        width: 40vmax !important;
        margin-bottom: 2vmax;
    }
}




.application_docs{
    /* background: red; */
    width: 80%;
    margin: 0 auto;
}

.application_docs button{
    width: 220px;
    padding: .5em;
    outline: none;
    border: none;
    background: #09560C;
    color: white;
    font-weight: 600;
    margin: .3em;
}

.application_docs span{
    color: #09560C;
    font-weight: 600;
}
.application_docs .container{
    width: 60%;
    background: #ebebf4;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: .5em;
    /* border: 2px solid #09560C; */
    border-radius: 5px;
    padding: .2em;

}
.application_docs .container .topsection{
    margin-bottom: .3em;
}
.application_docs .bottom{
    display: flex;
    align-items: center;
    justify-content: center;
}
.application_docs .container img{
    width: 100%;
}

@media screen and (max-width:768px) {
    .application_docs .container {
        width: 100%;
        margin: .5em 0;
        padding: 0;
    }
}