.page {
    min-height: 90vh;
    opacity: 0;
    animation: load 0.5s linear forwards;
}

#careerPage #doc-download {
    width: 90%;
    min-height: 2rem;
    line-height: 2rem;
    background-color: #ffc3c3;
    margin-left: 5%;
}

#careerPage .careers {
    top: 0.5rem;
    /* background-color: #F0F0F0; */
    background-color: white;
    width: 90%;
    height: inherit;
    min-height: max-content;
    margin: auto;
    display: grid;
    justify-content: space-around;
    justify-items: center;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 4rem;
}

#galleryPage {
    height: max-content !important;
    padding-bottom: 10%;
}
#galleryPage .galleryList {
    gap: 2vw;
    width: 90%;
    margin: auto;
    display: grid;
    height: inherit;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr))
}
.galleryList  div {
    width: 1fr;
    height: 50vh;
    transition: height 0.2s linear;
}
.galleryList div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: object-fit;
}

@keyframes load {
    from{opacity: 0;}   
    to{opacity: 1;}
}